import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import Loader from '../../atoms/Loader';
import notify from '../../../helpers/notification';
import Dialog from '../../atoms/Dialog';
import {
  BulkUserDeactivatePayload,
  deactivateBulkUser,
  UserDetails
} from '../../../services';
import { readExcelFile, exportToExcelFile } from '../../../helpers/common';
import './index.css';

export interface UsersBulkDeactivateDialogProps {
  buttonTitle?: string | null;
  open?: Boolean;
  onChange?: Function;
  loading?: Boolean;
}

export interface UsersWithValidation extends UserDetails {
  status?: string | null;
  msg?: string | null;
}

const UsersBulkDeactivateDialog = ({
  buttonTitle = null,
  open: isDialogOpen = false,
  onChange,
  loading = false
}: UsersBulkDeactivateDialogProps) => {
  const formRef = useRef<HTMLFormElement>(null);
  const [open, setOpen] = useState<Boolean>(false);
  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const [comment, setComment] = useState('');
  const [validation, setValidation] = useState({});
  const [resultStatus, setResultStatus] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [usersErrorMessage, setUsersErrorMessage] = useState<
    UsersWithValidation[]
  >([]);
  const authData = useSelector((state: any) => state?.auth);
  const { actualUserType } = authData;

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    setOpen(isDialogOpen);
  }, [isDialogOpen]);

  useEffect(() => {
    setComment('');
    setValidation({});
    setResultStatus('');
    setErrorMessage('');
    setUsersErrorMessage([]);
    if (formRef.current) {
      formRef.current.reset();
    }
  }, [open]);

  const dialogOpen = () => {
    setOpen(true);
    if (onChange) onChange(true);
  };

  const dialogClose = () => {
    setOpen(false);
    if (onChange) onChange(false);
  };

  const convertKeysToLowerCase = (array: any[]) => {
    return array.map((item) => {
      return Object.entries(item).reduce((acc, [key, value]) => {
        acc[key.toLowerCase()] = value;
        return acc;
      }, {} as any);
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    setResultStatus('');
    setErrorMessage('');
    setUsersErrorMessage([]);

    /* Read userList file by name */
    const file = e.currentTarget.usersList.files[0];
    try {
      setIsLoading(true);
      const resultData = await readExcelFile(file);

      const usersForDeactivate = (
        resultData && resultData?.length > 0
          ? convertKeysToLowerCase(resultData)
          : []
      ).map((user: any) => {
        return {
          userName: user['user name'] || '',
          email: user['email'] || '',
          userId: user['user id'] || '',
          companyName: user['company name'] || '',
          vendorId: user['vendor id'] || '',
          supplierSiteId: user['site id'] || '',
          justification: user['justification'] || ''
        };
      });
      const usersDeactivationPayload = {
        userProfileDeactiveList: usersForDeactivate,
        userType: actualUserType
          ? actualUserType?.map((item: any) => item?.toUpperCase())
          : []
      };

      const result: any = await deactivateBulkUser(usersDeactivationPayload);
      if (result.status?.toString()?.toLowerCase() === 'success') {
        setResultStatus('SUCCESS');
      } else if (result.status?.toString()?.toLowerCase() === 'failure') {
        setResultStatus('FAILED');
        setErrorMessage(result?.msg);
        setUsersErrorMessage(
          result?.response && result?.response?.length > 0
            ? result?.response
            : []
        );
      }
      if (formRef.current) {
        formRef.current.reset();
      }
      setIsLoading(false);
    } catch (error: any) {
      if (formRef.current) {
        formRef.current.reset();
      }
      setIsLoading(false);
      const notifyErrorMessage =
        error?.message || 'An error occurred while reading the file';
      notify(notifyErrorMessage, 'error');
    }

    setIsLoading(false);
  };

  const resetForm = () => {
    if (formRef.current) {
      formRef.current.reset();
    }
    setResultStatus('');
    setErrorMessage('');
    setUsersErrorMessage([]);
  };

  const downloadTemplate = () => {
    exportToExcelFile(
      [
        {
          'User Id': '',
          Email: '',
          'User Name': '',
          'Company Name': '',
          'Vendor Id': '',
          'Site Id': '',
          Justification: ''
        }
      ],
      'bulk_user_deactivate_template'
    );
  };

  return (
    <>
      {buttonTitle && (
        <a className="btn btn-primary" onClick={dialogOpen}>
          {buttonTitle}
        </a>
      )}
      <Dialog
        open={open}
        size="medium"
        onDialogClose={dialogClose}
        closeOnClickOutside={false}
        title={`Bulk Users Deactivate`}
        footer={false}
        body={
          <div>
            {isLoading && (
              <div className="center-holder-wrap">
                <Loader />
              </div>
            )}

            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    {resultStatus === 'SUCCESS' ? (
                      <>
                        <div className="alert alert--success">
                          <div className="alert__message">
                            <b>Users has been submitted successfully.</b> The
                            process to deactivate the users is in process and
                            will take upto 30 minutes. We will notify you via
                            email once the process is completed.
                          </div>
                        </div>
                        <div className="base-margin-top">
                          {' '}
                          To upload more file click{' '}
                          <a onClick={resetForm}>here</a>
                        </div>
                      </>
                    ) : null}
                    {resultStatus !== 'SUCCESS' ? (
                      <form ref={formRef} method="POST" onSubmit={handleSubmit}>
                        <p>
                          Please download the{' '}
                          <a onClick={downloadTemplate} download>
                            template file
                          </a>{' '}
                          and add your data based on the template before
                          uploading.
                        </p>
                        <div className="form-group">
                          <div className="form-group__text">
                            <input
                              name="usersList"
                              type="file"
                              accept=".csv, .xlsx"
                              required
                            />
                            <label>Select File (.xlsx | .csv)</label>
                          </div>
                        </div>
                        <button
                          disabled={isLoading as boolean}
                          type="submit"
                          className="btn base-margin-top"
                        >
                          Submit
                        </button>
                        <button
                          disabled={isLoading as boolean}
                          type="button"
                          className="btn btn--danger base-margin-top"
                          onClick={dialogClose}
                        >
                          Cancel
                        </button>
                      </form>
                    ) : null}
                    {resultStatus === 'FAILED' ? (
                      <div className="alert alert--warning">
                        <div
                          className="alert__message"
                          style={{ width: '100%' }}
                        >
                          {errorMessage}
                          <div className="users-error-message">
                            <ul className="">
                              {usersErrorMessage
                                ?.filter(
                                  (item) =>
                                    item?.status?.toLowerCase() === 'failure'
                                )
                                ?.map((user) => (
                                  <li key={user.email}>
                                    {user.email} - {user.msg}
                                  </li>
                                ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      />
    </>
  );
};

export default UsersBulkDeactivateDialog;
