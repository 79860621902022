import React, {
  useState,
  useMemo,
  useCallback,
  useRef,
  useEffect
} from 'react';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
// import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useSelector, useDispatch } from 'react-redux';
import { GridReadyEvent } from 'ag-grid-community';

import Loader from '../../atoms/Loader';
import PARemoveDialog from '../../organisms/PARemoveDialog';

const AddressTooltip = (props: any) => {
  const data = useMemo(
    () => props.api.getDisplayedRowAtIndex(props.rowIndex)?.data,
    []
  );

  return (
    <div
      className="custom-tooltip"
      style={{ backgroundColor: props.color || 'white' }}
    >
      <p>
        <span>Address Line 1: {data?.address1 || '-'}</span>
      </p>
      <p>
        <span>Address Line 2: {data?.address2 || '-'}</span>
      </p>
      <p>
        <span>City: {data?.city || '-'}</span>
      </p>
      <p>
        <span>State: {data?.state || '-'}</span>
      </p>
      <p>
        <span>Country: {data?.country || '-'}</span>
      </p>
      <p>
        <span>Postal Code: {data?.zip || '-'}</span>
      </p>
    </div>
  );
};

const ExternalUserDesignationDetail = ({
  userDesignations = [],
  userDetails,
  onUserUpdate
}: any) => {
  const authData = useSelector((state: any) => state.auth);
  const { actualUserType, user, userActiveRolePermissions } = authData;
  const gridRef: any = useRef(null);
  const [rowData, setRowData] = useState([]);
  const [action, setAction] = useState<string>('');

  const [paRemoveDialogOpen, setPaRemoveDialogOpen] = useState<any>(false);

  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    setRowData(userDesignations);
  }, [userDesignations]);

  useEffect(() => {
    setSelectedRows([]);
  }, [userDetails]);

  const filterParams = {
    suppressAndOrCondition: true,
    filterOptions: ['contains', 'notContains', 'equals', 'notEqual']
  };

  const columnsList = [
    {
      headerName: 'Designation',
      field: 'designationName',
      filter: true,
      filterParams,
      tooltipField: 'designationName',
      headerTooltip: 'Designation',
      pinned: 'left',
      width: 180
    },
    {
      headerName: 'Company Name',
      field: 'mcocompany',
      filter: true,
      filterParams,
      headerTooltip: 'Company Name',
      tooltipField: 'mcocompany',
      minWidth: 120,
      flex: 1
    },
    {
      headerName: 'Vendor Id',
      field: 'vendorId',
      filter: true,
      filterParams,
      headerTooltip: 'Vendor Id',
      tooltipField: 'vendorId',
      minWidth: 100,
      flex: 1
    },
    {
      headerName: 'Site Id',
      field: 'supplierSiteId',
      filter: true,
      filterParams,
      headerTooltip: 'Site Id',
      tooltipField: 'supplierSiteId',
      minWidth: 90,
      flex: 1
    },
    {
      headerName: 'Site Address',
      field: 'siteAddress',
      filter: true,
      tooltipField: 'siteAddress',
      minWidth: 240,
      flex: 1,
      tooltipComponent: AddressTooltip
    },
    {
      headerName: 'Primary Organization',
      field: 'primaryOrg',
      filter: true,
      filterParams,
      headerTooltip: 'Primary Organization',
      tooltipField: 'primaryOrg',
      minWidth: 140,
      flex: 1
    },

    {
      headerName: 'Secondary Organization',
      field: 'secOrg',
      filter: true,
      filterParams,
      headerTooltip: 'Secondary Organization',
      tooltipField: 'secOrg',
      minWidth: 140,
      flex: 1,
      cellRenderer: (params: any) => {
        return params?.value?.split(',')?.join(', ') || '-';
      }
    }
  ]
    .map((item: any) => {
      let itemTemp: any = item;
      itemTemp.resizable = true;
      itemTemp.suppressHeaderMenuButton = true;

      itemTemp.columnChooserParams = {
        suppressColumnSelectAll: false,
        suppressColumnExpandAll: false,
        suppressColumnFilter: false
      };

      itemTemp.menuTabs = ['filterMenuTab'];
      itemTemp.sortable = true;
      return itemTemp;
    })
    .filter((item: any) => {
      if (item?.field === 'action' && user?.ccoid === userDetails?.userId) {
        return false;
      }
      return true;
    });
  const columnDefs = useMemo(() => columnsList, []);

  const onGridReady = useCallback((params: GridReadyEvent) => {
    // Do any setup on grid ready
  }, []);

  const onSelectionChanged = useCallback(() => {
    const selectedNodes = gridRef?.current!.api.getSelectedRows();

    //  const selectedData = selectedNodes.map((node: any) => node?.data);
    setSelectedRows(selectedNodes);
  }, []);

  const gridOptions = useMemo<AgGridReactProps>(
    () => ({
      selectionColumnDef: {
        sortable: false,
        width: 40,
        maxWidth: 40,
        suppressHeaderMenuButton: true,
        pinned: 'left'
      },
      rowSelection: {
        mode: 'multiRow',
        headerCheckbox: true
      },
      suppressContextMenu: true,
      onSelectionChanged: onSelectionChanged
    }),
    [onSelectionChanged]
  );

  const onClickRemoveButton = (action = '') => {
    setPaRemoveDialogOpen(true);
    setAction(action);
  };

  const onUserUpdateDone = () => {
    onUserUpdate();
  };

  const getRowStyle = (params: any) => {
    if (params.node.rowIndex % 2 === 0) {
      return { background: '#f2f2f2' };
    }
  };

  const getOverlayNoRowsTemplate = () => {
    return 'No data found';
  };

  return (
    <div className="card qtr-margin-top">
      <div className="card-header">
        <div className="flex">
          <h5 className="text-primary">User Holding Designations</h5>

          {user?.ccoid === userDetails?.userId ? null : (
            <button
              type="button"
              className="btn btn--dark btn--small base-margin-left"
              disabled={!selectedRows?.length}
              onClick={() => onClickRemoveButton('REMOVE')}
            >
              Remove
            </button>
          )}
        </div>
      </div>
      <div className="card-body">
        {!rowData?.length ? (
          <div className="text-center">No Data Found</div>
        ) : null}

        <div
          className={`ag-theme-alpine`}
          style={{
            height: rowData?.length ? 485 : 180,
            width: '100%',
            display: rowData?.length ? 'block' : 'none'
          }}
        >
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs as any}
            gridOptions={gridOptions}
            onGridReady={onGridReady}
            overlayNoRowsTemplate={getOverlayNoRowsTemplate()}
            loadingOverlayComponent={Loader}
            enableCellTextSelection={true}
            suppressCellFocus={true}
            getRowStyle={getRowStyle}
            headerHeight={38}
            rowHeight={38}
            animateRows={true}
            tooltipShowDelay={0}
          />
        </div>

        <PARemoveDialog
          details={userDetails}
          selectedRows={selectedRows}
          allRows={
            userDesignations && userDesignations?.length ? userDesignations : []
          }
          open={paRemoveDialogOpen}
          action={action || ''}
          onChange={(dialogStatus: boolean) => {
            setPaRemoveDialogOpen(dialogStatus);
          }}
          onSuccess={onUserUpdateDone}
        />
      </div>
    </div>
  );
};

export default ExternalUserDesignationDetail;
