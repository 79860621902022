import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';

import { commonConfig } from '../../../config/common';
import { config } from '../../../config/config';
import OneAccessRedirect from '../../organisms/OneAccessRedirect';
import { fetchSCOUserGroup } from '../../../actions';
import FullScreenLoader from '../../molecules/FullScreenLoader';

const UserRoleValidateWrapper = () => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState<string | undefined>();

  const userDetailsData: any = useSelector<any>((state) => state.auth);

  const {
    mainUserType,
    userRoles,
    actualRolePermissions,
    userActiveRolePermissions,
    getSCOUserGroupStatus,
    getSCOUserGroupErrorMessage
  } = userDetailsData;
  const scoUserGroupStatus: any = useSelector<any>(
    (state) => state?.auth?.scoUserGroupStatus
  );

  const checkIsUserHasCapableRole = () => {
    const requiredRoleForPAMSponsor = actualRolePermissions?.find(
      (item: any) =>
        item?.name?.toLocaleLowerCase() ===
        commonConfig?.userMandatoryRole?.name?.toLocaleLowerCase()
    );
    if (requiredRoleForPAMSponsor) {
      const isUserHasHigherPriorRole = userActiveRolePermissions?.filter(
        (item: any) => {
          return item?.priority < requiredRoleForPAMSponsor?.priority;
        }
      );
      if (isUserHasHigherPriorRole?.length) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    let isAllowed = false;
    let redirectToOneAccess = false;
    if (mainUserType === 'EXTERNAL') {
      isAllowed = true;
    } else if (mainUserType === 'INTERNAL') {
      if (typeof getSCOUserGroupStatus === 'undefined') {
        dispatch(fetchSCOUserGroup());
      } else if (getSCOUserGroupStatus === 'SUCCESS') {
        if (scoUserGroupStatus?.isSCOUser?.toLowerCase() === 'sco') {
          isAllowed = true;
        } else if (
          scoUserGroupStatus?.userType?.toLowerCase() === 'vendor' &&
          scoUserGroupStatus?.groupName ===
            config?.nonSCOVendorUserMandatoryRole?.name
        ) {
          isAllowed = true;
        } else if (
          userRoles.includes(commonConfig?.userMandatoryRole?.name) ||
          checkIsUserHasCapableRole()
        ) {
          isAllowed = true;
        } else {
          redirectToOneAccess = true;
        }
      }
    }

    if (isAllowed) {
      setStatus('SUCCESS');
    } else if (redirectToOneAccess) {
      setStatus('REDIRECT_ONEACCESS');
    }
  }, [mainUserType, userRoles, scoUserGroupStatus]);

  return (
    <>
      {status === 'REDIRECT_ONEACCESS' && (
        <OneAccessRedirect
          roleRequest={[
            ...(scoUserGroupStatus?.userType?.toLowerCase() === 'vendor' &&
            scoUserGroupStatus?.groupName !==
              config?.nonSCOVendorUserMandatoryRole?.name
              ? [config?.nonSCOVendorUserMandatoryRole?.displayName]
              : []),
            ...(!userRoles.includes(commonConfig?.userMandatoryRole?.name) &&
            !checkIsUserHasCapableRole()
              ? [commonConfig?.userMandatoryRole?.displayName]
              : [])
          ]}
        />
      )}

      {status === 'SUCCESS' && <Outlet />}

      {getSCOUserGroupStatus === 'PENDING' && (
        <FullScreenLoader title={'Fetching user details'} />
      )}

      {getSCOUserGroupStatus === 'FAILURE' && (
        <div className="base-margin-top">
          <div className="alert alert--warning">
            <div className="alert__message">{getSCOUserGroupErrorMessage}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserRoleValidateWrapper;
