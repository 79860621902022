import React, { useEffect, useState } from 'react';
import DateRangePicker from 'rsuite/DateRangePicker';
import 'rsuite/dist/rsuite.min.css';

import './index.css';
import Dropdown from '../../atoms/Dropdown';
import Select, { SelectOption } from '../../molecules/Select';
import { DatePicker } from 'rsuite';
import CreatableSearchSelect from '../CreatableSearchSelect';
import FilterSelect from '../FilterSelect';
import { noteToolTip } from '../../../helpers/common';
import { addMonths, addYears } from 'date-fns';
export interface FiltersProps {
  onChange?: Function;
  filters?: any;
  validation?: any;
  searchButton?: any;
  advancedSearch?: any;
}

const Filters = ({
  onChange,
  filters,
  validation = [],
  searchButton,
  advancedSearch
}: FiltersProps) => {
  const [filterList, setFilterList] = useState<any>([]);

  useEffect(() => {
    setFilterList(filters);
  }, [filters]);

  const onFilterSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setFilterList((prevValue: any) => {
      const changedValue = (prevValue || []).map((item: any) => {
        if (item?.name === event.target.name) {
          return {
            ...item,
            show: checked,
            ...{ value: !checked ? '' : item?.value }
          };
        }
        return item;
      });
      if (onChange) {
        onChange(changedValue);
      }
      return changedValue;
    });
  };

  const onFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterList((prevValue: any) => {
      const changedValue = (prevValue || []).map((item: any) => {
        if (item?.name === event.target.name) {
          return { ...item, value: event.target.value };
        }
        return item;
      });
      if (onChange) {
        setTimeout(() => {
          onChange(changedValue);
        });
      }
      return changedValue;
    });
  };

  const onChangeDate = (name: string, val: any) => {
    setFilterList((prevValue: any) => {
      const changedValue = (prevValue || []).map((item: any) => {
        if (item?.name === name) {
          return { ...item, value: val };
        }
        return item;
      });
      if (onChange) {
        setTimeout(() => {
          onChange(changedValue);
        });
      }
      return changedValue;
    });
  };

  const onDateRangeFilterChange = (name: string, dateRange: Date[] | null) => {
    setFilterList((prevValue: any) => {
      const changedValue = (prevValue || []).map((item: any) => {
        if (item?.name === name) {
          return { ...item, value: dateRange };
        }
        return item;
      });
      if (onChange) {
        setTimeout(() => {
          onChange(changedValue);
        });
      }
      return changedValue;
    });
  };

  const onSelectChange = (name: string, newValue: any) => {
    setFilterList((prevValue: any) => {
      const changedValue = (prevValue || []).map((item: any) => {
        if (item?.name === name) {
          return {
            ...item,
            value: Array.isArray(newValue)
              ? newValue
              : newValue?.value
              ? newValue
              : undefined
          };
        }
        return item;
      });
      if (onChange) {
        setTimeout(() => {
          onChange(changedValue);
        });
      }
      return changedValue;
    });
  };

  const onLastLoginChange = (name: string, value: any) => {
    let obj = {
      value: null,
      lastLoginStartDate: undefined,
      lastLoginEndDate: undefined
    } as any;
    if (value === 'all' || value === 'noLoggedInDate') {
      obj = {
        value: null,
        lastLoginStartDate: undefined,
        lastLoginEndDate: undefined
      };
    } else if (value === 'last3Months') {
      obj = {
        value: [addMonths(new Date(), -3), new Date()],
        lastLoginStartDate: addMonths(new Date(), -3),
        lastLoginEndDate: new Date()
      };
    } else if (value === 'last6Months') {
      obj = {
        value: [addMonths(new Date(), -6), new Date()],
        lastLoginStartDate: addMonths(new Date(), -6),
        lastLoginEndDate: new Date()
      };
    } else if (value === 'last1Year') {
      obj = {
        value: [addMonths(new Date(), -1), new Date()],
        lastLoginStartDate: addYears(new Date(), -1),
        lastLoginEndDate: new Date()
      };
    } else if (value === 'customDate') {
      obj = {
        value: null,
        lastLoginStartDate: undefined,
        lastLoginEndDate: undefined
      };
    }
    setFilterList((prevValue: any) => {
      const changedValue = (prevValue || []).map((item: any) => {
        if (item?.name === name) {
          return {
            ...item,
            ...obj,
            selectValue: value || ''
          };
        }
        return item;
      });

      if (onChange) {
        setTimeout(() => {
          onChange(changedValue);
        });
      }
      return changedValue;
    });
  };

  const getValidationData = (name: string) => {
    return validation?.find((item: any) => item?.name === name);
  };

  return (
    <div className="filter-wrap">
      {filterList && filterList?.length
        ? filterList.map((item: any, index: number) => {
            const validationData = getValidationData(item?.name);
            return item?.show ? (
              <div
                className={`filter-item ${
                  item?.className ? item?.className : ''
                }`}
                key={`filter${index}`}
              >
                <div
                  className={`form-group form-group--horizontal ${
                    validationData ? 'form-group--error' : null
                  }`}
                >
                  <div className="form-group__text">
                    {item?.type === 'text' || item?.type === 'number' ? (
                      <input
                        name={item?.name || ''}
                        type={item?.type}
                        data-testid={item?.name || ''}
                        value={item?.value || ''}
                        onChange={onFilterChange}
                      />
                    ) : null}

                    <label
                      className="filter-item-title"
                      htmlFor="input-size-default"
                    >
                      {item?.label || item?.name}{' '}
                      {item?.tooltip
                        ? noteToolTip(item?.tooltip?.id, item?.tooltip?.message)
                        : null}
                    </label>
                    {item?.type === 'date' ? (
                      <DatePicker
                        aria-invalid="true"
                        editable={false}
                        container={() =>
                          document.getElementById('startDateContainer')!
                        }
                        format="MM-dd-yyyy"
                        className={`datepicker ${
                          validationData ? 'datepicker--error' : null
                        }`}
                        menuClassName="datepicker-menu"
                        onChange={(val) => onChangeDate(item?.name, val)}
                        value={item?.value || null}
                      />
                    ) : null}
                    {item?.type === 'dateRange' ? (
                      <DateRangePicker
                        editable={false}
                        format="MM-dd-yyyy"
                        placeholder={'mm-dd-yyyy ~ mm-dd-yyyy'}
                        onChange={(val) =>
                          onDateRangeFilterChange(item?.name, val)
                        }
                        value={item?.value}
                      />
                    ) : null}
                    {item?.type === 'select' ? (
                      <Select
                        isSearchable={false}
                        value={
                          item?.value
                            ? {
                                value: item?.value?.value,
                                label: item?.value?.label
                              }
                            : ''
                        }
                        isClearable
                        name={item?.name || ''}
                        options={
                          item?.options?.length
                            ? item?.options?.map(
                                (item: { value: string; label: string }) => {
                                  return {
                                    value: item?.value,
                                    label: item?.label
                                  };
                                }
                              )
                            : []
                        }
                        className={`basic-select ${
                          validationData ? 'react-select-error' : null
                        }`}
                        classNamePrefix="select"
                        onChange={(newValue: any) =>
                          onSelectChange(item?.name, newValue)
                        }
                      />
                    ) : null}
                    {item?.type === 'creatableSelect' ? (
                      <CreatableSearchSelect
                        className={`basic-select creatable-select ${
                          validationData ? 'react-select-error' : null
                        }`}
                        classNamePrefix="creatable-select"
                        values={item?.value || []}
                        onChange={(newValue: any) => {
                          onSelectChange(item?.name, newValue);
                        }}
                      />
                    ) : null}
                    {item?.type === 'autoComplete' ? (
                      <FilterSelect
                        className={`basic-select creatable-select ${
                          validationData ? 'react-select-error' : null
                        }`}
                        classNamePrefix="creatable-select"
                        value={item?.value || []}
                        controlShouldRenderValue={true}
                        name={item?.name}
                        renderOptions={item?.renderOptions}
                        isMulti
                        cacheOptions
                        placeholder={item?.placeholder}
                        loadOptions={item?.options}
                        onChange={(newValue: any) => {
                          onSelectChange(item?.name, newValue);
                        }}
                      />
                    ) : null}

                    {item?.type === 'lastLogin' ? (
                      <>
                        <div className="form-group__text select">
                          <select
                            name="loggedInDateType"
                            value={item?.selectValue || ''}
                            onChange={(
                              event: React.ChangeEvent<HTMLSelectElement>
                            ) =>
                              onLastLoginChange(item?.name, event.target.value)
                            }
                            id="select-companySearchBy"
                            className="qtr-margin-right"
                          >
                            <option value="all">All</option>
                            <option value="last3Months">Last 3 Months</option>
                            <option value="last6Months">Last 6 Months</option>
                            <option value="last1Year">Last 1 Year</option>
                            <option value="customDate">Custom Date</option>
                            <option value="noLoggedInDate">
                              No LoggedIn Details
                            </option>
                          </select>
                        </div>

                        <DateRangePicker
                          style={{
                            border: '1px solid #dee2e6',
                            borderRadius: '2px'
                          }}
                          container={() =>
                            document.getElementById('startDateContainer')!
                          }
                          format="MM-dd-yyyy"
                          className="range-datepicker"
                          menuClassName="datepicker-menu"
                          editable={false}
                          disabled={item?.selectValue !== 'customDate'}
                          onChange={(val) =>
                            onDateRangeFilterChange(item?.name, val)
                          }
                          ranges={[
                            {
                              label: 'Last 3 Months',
                              value: [addMonths(new Date(), -3), new Date()]
                            },
                            {
                              label: 'Last 6 Months',
                              value: [addMonths(new Date(), -6), new Date()]
                            },
                            {
                              label: 'Last 1 Year',
                              value: [addYears(new Date(), -1), new Date()]
                            }
                          ]}
                          value={item?.value}
                        />
                      </>
                    ) : null}
                  </div>
                  {validationData ? (
                    <div className="form-group__help" role="alert">
                      <span>{validationData?.message}</span>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null;
          })
        : null}

      <div className="filter-item">
        <Dropdown
          title={
            <div className="dot-btn">
              <span className="icon-more rotate-90"></span>
            </div>
          }
          showIcon={false}
          content={
            <div>
              {filterList && filterList?.length
                ? filterList.map((item: any, index: number) => {
                    return (
                      <div
                        className={`form-group filter-check-item ${
                          item?.disabled ? 'disabled' : ''
                        }`}
                        key={`filterSelect${index}`}
                      >
                        <label className="checkbox">
                          <input
                            type="checkbox"
                            checked={item?.show}
                            name={item?.name}
                            onChange={onFilterSelectChange}
                            disabled={item?.disabled}
                          />
                          <span className="checkbox__input"></span>
                          <span className="checkbox__label">
                            {item?.label || item?.name}
                          </span>
                        </label>
                      </div>
                    );
                  })
                : null}
            </div>
          }
        />
      </div>

      {searchButton ? (
        <div className="filter-item" key={`moreFilter`}>
          {searchButton}
        </div>
      ) : null}
      {advancedSearch ? (
        <div className="filter-item" key={`advancedSearch`}>
          {advancedSearch}
        </div>
      ) : null}
    </div>
  );
};

export default Filters;
