import httpUtil from '../helpers/interceptor';
import notify from '../helpers/notification';

export interface UserRevalidationPayload {
  revalidationUserId: string;
}

export const fetchUserRevalidationDetails = async (body: any = {}) => {
  try {
    const res: any = await httpUtil.post(
      `/api/company/revalidation/v1/company-account-revalidation/details`,
      body
    );
    return res;
  } catch (error: any) {
    const errorMessage = `An error occurred while fetching user Revalidation Details ${
      error?.response?.data?.error ? ': ' + error?.response?.data?.error : ''
    }`;
    notify(errorMessage, 'error');
    return Promise.reject(error);
  }
};

export const fetchUserRevalidationList = async (body: any = {}) => {
  try {
    const res: any = await httpUtil.post(
      `/api/user/revalidation/v1/get/userRevalidate`,
      body
    );
    return res;
  } catch (error: any) {
    const errorMessage = `An error occurred while fetching user reviews ${
      error?.response?.data?.error ? ': ' + error?.response?.data?.error : ''
    }`;
    notify(errorMessage, 'error');
    return Promise.reject(error);
  }
};

export const fetchUserArchiveList = async (body: any = {}) => {
  try {
    const res: any = await httpUtil.post(
      `/api/user/revalidation/v1/get/userRevalidate/archive/data`,
      body
    );
    return res;
  } catch (error: any) {
    const errorMessage = `An error occurred while fetching user reviews ${
      error?.response?.data?.error ? ': ' + error?.response?.data?.error : ''
    }`;
    notify(errorMessage, 'error');
    return Promise.reject(error);
  }
};

export const userRevalidationBulkUpdate = async (
  taskName: string,
  action: string,
  body: any = {}
) => {
  try {
    let apiUrl = '';
    if (taskName === 'PA_REVIEW' && action === 'APPROVE') {
      apiUrl = '/api/user/revalidation/v1/bulk/pa/approval';
    }

    if (taskName === 'PA_REVIEW' && action === 'DENY') {
      apiUrl = '/api/user/revalidation/v1/bulk/pa/denial';
    }

    if (taskName === 'PAM_REVIEW' && action === 'APPROVE') {
      apiUrl = '/api/user/revalidation/v1/bulk/pam/approval';
    }

    if (taskName === 'PAM_REVIEW' && action === 'DENY') {
      apiUrl = '/api/user/revalidation/v1/bulk/pam/denial';
    }

    const res: any = await httpUtil.post(apiUrl, body);
    return res;
  } catch (error: any) {
    const errorMessage = `An error occurred while updating user review  ${
      error?.response?.data?.error ? ': ' + error?.response?.data?.error : ''
    }`;
    notify(errorMessage, 'error');
    return Promise.reject(error);
  }
};

export const initiateUserRevalidation = async (body: any = {}) => {
  try {
    const res: any = await httpUtil.post(
      `/api/user/revalidation/v1/user/revalidation/initiate`,
      body
    );
    return res;
  } catch (error: any) {
    const errorMessage = `An error occurred while initiating user revalidation ${
      error?.response?.data?.error ? ': ' + error?.response?.data?.error : ''
    }`;
    notify(errorMessage, 'error');
    return Promise.reject(error);
  }
};
