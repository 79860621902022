import DOMPurify from 'dompurify';

function HTMLDisplay(htmlContent: string | Node) {
  const sanitizedHtml = DOMPurify.sanitize(htmlContent, {
    ALLOWED_TAGS: [
      'b',
      'i',
      'em',
      'strong',
      'a',
      'br',
      'div',
      'p',
      'span',
      'ul',
      'li',
      'ol',
      'table',
      'thead',
      'tbody',
      'tr',
      'th',
      'td',
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'img',
      'section',
      'button'
    ],
    ALLOWED_ATTR: ['href', 'title', 'class']
  });
  return <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
}
export default HTMLDisplay;
